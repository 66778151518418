<template>
  <div class="purple-docs-documents">
    <b-card title="Manage Documents">
      <b-card-text>
        This page shows all Purple Docs documents.
      </b-card-text>
    </b-card>
    <b-card no-body class="mb-0">
      <!-- Search Filters -->
      <div class="row px-2 pt-1 justify-content-end">
        <section class="col-12 col-lg-3 col-md-6 mb-1">
          <label>Title Search</label>
          <b-input-group>
            <b-form-input
              v-model="filters.search"
              class="d-inline-block"
              placeholder="Search by title..."
              @keydown.enter="getMoreDocuments(1)"
            />
            <b-input-group-append>
              <b-button @click="getMoreDocuments(1)">
                <i class="fas fa-search" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </section>

        <section class="col-12 col-lg-3 col-md-6 mb-1">
          <label>Resident ID</label>
          <b-input-group>
            <b-form-input
              v-model="filters.owner"
              class="d-inline-block"
              placeholder="Search by resident ID..." @keydown.enter="getMoreDocuments(1)"
            />
            <b-input-group-append>
              <b-button @click="getMoreDocuments(1)">
                <i class="fas fa-search" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </section>

        <section class="col-12 col-lg-3 col-md-6 mb-1">
          <label>Document Search</label>
          <b-input-group>
            <b-form-input
              v-model="filters.contentSearch"
              class="d-inline-block"
              placeholder="Search within documents..." @keydown.enter="getMoreDocuments(1)"
            />
            <b-input-group-append>
              <b-button @click="getMoreDocuments(1)">
                <i class="fas fa-search" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </section>

        <section class="col-12 col-lg-3 col-md-6 mb-1">
          <label>Status</label>
          <div class="mb-2 w-100 d-flex justify-content-between">
            <b-input-group-append class="w-100 mr-1">
              <model-list-select v-model="filters.is_deleted"
                                 :list="[
                                   {status: 'All', value: ''},
                                   {status: 'Deleted', value: 'true'},
                                   {status: 'Not Deleted', value: 'false'},
                                 ]"
                                 option-value="value"
                                 option-text="status"
                                 placeholder="Select Status"
                                 @input="getDocuments"
              />
            </b-input-group-append>
            <b-button @click="clearFilters()">Clear</b-button>
          </div>
        </section>
      </div>

      <!-- Table -->
      <section>
        <b-table
          class="data-table"
          hover
          :items="documents"
          :fields="headers"
          :current-page="currentPage"
          :per-page="0"
          responsive
        >

          <template #cell(title)="data">
            <router-link :to="{ name: 'purple-docs-document', params: { id: data.item.id.toString() } }"
                         class="font-weight-bold d-block text-nowrap"
                         :class="{ 'text-secondary': data.item.deleted_at }"
            >
              {{ data.item.title }}
            </router-link>
          </template>

          <template #cell(resident_id)="data">
            {{ data.item.owner ? data.item.owner.identifier : '' }}
          </template>

          <template #cell(last_opened)="data">
            {{ data.item.last_opened | formatDateTime }}
          </template>

          <template #cell(updated_at)="data">
            {{ data.item.updated_at | formatDateTime }}
          </template>

          <template #cell(deleted_at)="data">
            {{ data.item.deleted_at | formatDateTime }}
          </template>

          <template #cell(actions)="data">
            <b-dropdown class="m-0" variant="link" right toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <i class="fas fa-ellipsis-v" />
              </template>
              <b-dropdown-item-button class="full-nested-btn"
                                      @click="showDocumentModal(data.item, 'modal-delete-document')"
              >
                Delete
              </b-dropdown-item-button>
            </b-dropdown>
          </template>
        </b-table>

        <b-row>
          <b-col v-if="total_count > filters.page_size" md="6" class="my-1">
            <b-pagination v-model="currentPage" :total-rows="total_count" :per-page="filters.page_size" class="my-0" @change="getMoreDocuments" />
          </b-col>
          <p v-if="total_count === 0" class="col-6 ml-2 my-1">No records</p>
        </b-row>
      </section>
    </b-card>

    <b-modal id="modal-delete-document" title="Delete a document" hide-footer>
      <delete-modal
        v-if="selectedDocument"
        :subtitle="selectedDocument.title"
        title="Are you sure you wish to delete this document? This action cannot be undone."
        @close="refreshDocuments()"
        @delete="deleteDocument(selectedDocument.id)"
      />
    </b-modal>

  </div>
</template>

<script>
import 'vue-search-select/dist/VueSearchSelect.css';
import helperService from "@/services/HelperService";
import DeleteModal from "@/components/modals/DeleteModal.vue";
import PurpleDocsDocumentService from "@/services/PurpleDocsDocumentService";
import {ModelListSelect} from "vue-search-select";

export default {
  name: 'PurpleDocDocuments',
  components: { DeleteModal, ModelListSelect },
  props: {},
  data() {
    return {
      headers: [
        {
          key: 'title',
          label: 'Title',
        },
        {
          key: 'resident_id',
          label: 'Resident ID',
        },
        {
          key: 'last_opened',
          label: 'Last Opened',
        },
        {
          key: 'updated_at',
          label: 'Last Updated',
        },
        {
          key: 'deleted_at',
          label: 'Deleted On',
        },
        {
          key: 'actions',
          label: '',
          class: 'text-right',
        },
      ],
      documents: [],
      selectedDocument: null,
      filters: {
        page: 0,
        page_size: 25,
        search: '',
        owner: '',
        contentSearch: '',
        is_deleted: '',
      },
      currentPage: 1,
      total_count: 0,
    };
  },
  mounted() {
    this.getDocuments();
  },
  methods: {
    getMoreDocuments(page) {
      this.filters.page = page - 1 // api index starts at 0, bootstrap starts at 1 lol
      this.getDocuments()
    },
    async getDocuments() {
      try {
        const { data } = await PurpleDocsDocumentService.listDocuments(this.filters)
        this.documents = data.data
        this.filters.page = data.meta.page;
        this.filters.page_size = data.meta.page_size;
        this.total_count = data.meta.total_count;
      } catch(err) {
        helperService.showNotfyErr(this.$toast, err, 'Could not fetch documents, please refresh and try again')
      }
    },
    async deleteDocument(id) {
      try {
        await PurpleDocsDocumentService.deleteDocument(id)
        helperService.showNotfySuccess(this.$toast, `The document has been successfully deleted.`)
      } catch(err) {
        helperService.showNotfyErr(this.$toast, err, 'An error occurred while deleting the document. Please try again.')
      } finally {
        this.refreshDocuments()
      }
    },
    closeModals() {
      this.$bvModal.hide('modal-delete-document');
    },
    showDocumentModal(document, modalName) {
      this.selectedDocument = document
      this.$bvModal.show(modalName);
    },
    refreshDocuments() {
      this.closeModals()
      this.getDocuments()
    },
    clearFilters() {
      this.filters = {
        page: 0,
        page_size: 25,
        search: '',
        status: '',
      }
      this.getDocuments();
    },
  },
};
</script>

<style lang="scss">

</style>

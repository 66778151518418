import { render, staticRenderFns } from "./PurpleDocsDocuments.vue?vue&type=template&id=aefc12f0"
import script from "./PurpleDocsDocuments.vue?vue&type=script&lang=js"
export * from "./PurpleDocsDocuments.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
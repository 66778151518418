import HelperService from '@/services/HelperService';
import axios from '@/libs/axios';

const baseUrl = '/purple-docs/documents'

export default {
    listDocuments(filters) {
        const queryString = HelperService.buildSearchQueryString(filters)
        return axios.get(`${baseUrl}${queryString}`)
    },
    getDocument(id, filters) {
      const queryString = HelperService.buildSearchQueryString(filters)
        return axios.get(`${baseUrl}/${id}${queryString}`)
    },
    deleteDocument(id) {
        return axios.delete(`${baseUrl}/${id}`)
    },
    listDocumentRevisions(id, filters) {
      const queryString = HelperService.buildSearchQueryString(filters)
      return axios.get(`${baseUrl}/${id}/revision${queryString}`)
    }
}
